<template>
  <div class="page">
    <PageTitle title="菜单管理"></PageTitle>
    <div class="content">
      <div class="add">
        <el-button class="tb-button" type="primary" @click="add">添加主菜单</el-button>
      </div>
      <div class="tablebox">
        <el-table
          ref="multipleTable"
          row-key="id"
          :data="menuList"
          class="tb-table"
        >
          <el-table-column prop="menuName" label="菜单"/>
          <el-table-column label="图标" min-width="50">
            <template slot-scope="scope">
              <img v-if="scope.row.icon" :src="scope.row.icon" width="14px" alt="icon">
            </template>
          </el-table-column>
          <el-table-column prop="type" label="类型"/>
          <el-table-column prop="menuUrl" label="路径"/>
          <el-table-column label="操作" min-width="150">
            <template slot-scope="scope">
              <template v-if="!scope.row.menuUrl">
                <span class="edit" @click="setMenu(scope.row, 2)">添加子菜单</span>
                <el-divider direction="vertical"></el-divider>
              </template>
              <span class="edit" @click="setMenu(scope.row, 1)">编辑</span>
              <el-divider direction="vertical"></el-divider>
              <span class="edit" @click="linkPost(scope.row.id)">关联接口</span>
              <el-divider direction="vertical"></el-divider>
              <span class="edit" @click="deleteMenu(scope.row.id)">删除</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="menuVisible"
      @close="close"
      width="500px"
    >
      <el-form :model="menu" :rules="rules" ref="ruleForm" class="tb-form" label-width="140px" label-position="right">
        <el-form-item label="菜单名字：" prop="name">
          <el-input v-model="menu.name" placeholder="请输入菜单名字" style="width: 226px;"></el-input>
        </el-form-item>
        <el-form-item label="菜单地址：">
          <el-input v-model="menu.url" placeholder="请输入菜单地址" style="width: 226px;"></el-input>
        </el-form-item>
        <el-form-item label="菜单图标：" v-if="canUpload">
          <template>
            <div class="menuicon">
              <Upload id="icon" src="menuicon" @success="onIconUpload">
                <div class="icon" v-if="menu.icon">
                  <img :src="menu.icon" alt="icon" width="100%">
                  <span class="exchange">更换图片</span>
                </div>
                <div class="menuicon-box flex-center" v-else>
                  <i class="el-icon-plus"></i>
                  <span class="text">点击上传</span>
                </div>
              </Upload>
            </div>
          </template>
        </el-form-item>
        <el-form-item label="菜单类型：" prop="type">
          <el-radio-group v-model="menu.type">
            <el-radio :label="1">站内路由</el-radio>
            <el-radio :label="2">站外路径</el-radio>
            <el-radio :label="3">无跳转</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="menuVisible = false">取 消</el-button>
        <el-button type="primary" @click="confirm">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="关联接口"
      :visible.sync="linkVisible"
      @close="linkClose"
      width="800px"
    >
      <div class="add" style="margin-bottom: 24px;">
        <el-button class="tb-button" type="primary" @click="addPost">添加接口</el-button>
      </div>
      <el-table
        :data="linkData"
        class="tb-table"
        height="400px"
      >
        <el-table-column prop="requestUrl" label="请求地址"/>
        <el-table-column prop="method" label="请求方式"/>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <span class="edit" @click="editPost(scope.row)">编辑</span>
            <el-divider direction="vertical"></el-divider>
            <span class="edit" @click="deletePost(scope.row.id)">删除</span>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <el-dialog
      title="编辑接口"
      :visible.sync="postVisible"
      width="400px"
    >
      <div class="flex post-item">
        <span>请求地址：</span>
        <el-input v-model="post.url" style="width: 216px;"></el-input>
      </div>
      <div class="flex post-item">
        <span>请求方式：</span>
        <el-radio-group v-model="post.type">
          <el-radio label="POST">POST</el-radio>
          <el-radio label="GET">GET</el-radio>
        </el-radio-group>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="postVisible = false">取 消</el-button>
        <el-button type="primary" @click="postConfirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Upload from '@/components/Upload'
import PageTitle from '@/components/PageTitle'
export default {
  name: 'MenuList',
  components: {
    PageTitle,
    Upload
  },
  data() {
    return {
      title: '新建主菜单',
      menuList: [],
      menuVisible: false,
      type: {
        1: '站内路由',
        2: '站外路径',
        3: '无跳转'
      },
      menu: {
        name: '',
        url: '',
        icon: '',
        type: ''
      },
      rules: {
        name: [{required: true, message: '请输入菜单名字'}],
        type: [{required: true, message: '请选择菜单类型'}]
      },
      linkData: [],
      post: {
        url: '',
        type: '',
        menuId: ''
      },
      linkVisible: false,
      postVisible: false
    }
  },
  computed: {
    canUpload() {
      return !this.menu.pid
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      const url = 'admin/menu/list'
      const data = await this.$https.get(url)
      if (!data) return
      const menu = (arr) => {
        if (!Array.isArray(arr)) return []
        arr.forEach(item => {
          item.type = this.type[item.menuType]
          item.icon = this.$img(item.menuIcon)
          if (item.children) menu(item.children)
        })
        return arr
      }
      this.menuList = menu(data)
    },
    add() {
      this.title = '新建主菜单'
      this.menuVisible = true
    },
    onIconUpload(url) {
      this.menu.icon = url
    },
    setMenu(row, type) {
      // type 1编辑, 2添加子菜单
      if (type === 1) {
        this.title = '编辑菜单'
        this.menu.id = row.id
        this.menu.pid = row.pid
        this.menu.name = row.menuName
        this.menu.url = row.menuUrl
        this.menu.type = row.menuType
        this.menu.icon = row.icon
      } else {
        this.title = '添加子菜单'
        this.menu.pid = row.id
      }
      this.menuVisible = true
    },
    deleteMenu(id) {
      this.$confirm('是否删除此菜单？若为父级菜单则会同步删除子菜单。', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const url = 'admin/menu/delete'
        const params = { id }
        this.$https.post(url, params).then(data => {
          if (data) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            })
            this.getList()
          }
        })
      }).catch(() => {})
    },
    async confirm() {
      const rule = await new Promise(resolve => {
        this.$refs.ruleForm.validate((valid) => {
          resolve(valid)
        })
      })
      if (!rule) return
      const {id, pid, icon} = this.menu
      let menuicon = {
        h: 100,
        w: 100,
        url: icon
      }
      if (icon) menuicon = JSON.stringify(menuicon)
      const params = {
        menu_name: this.menu.name,
        menu_url: this.menu.url,
        menu_type: this.menu.type,
        menu_icon: icon ? menuicon : ''
      }
      if (id) params.id = id
      if (pid) params.pid = pid
      const url = 'admin/menu/edit'
      const data = await this.$https.post(url, params)
      if (data) {
        this.$message.success(this.menu.id ? '编辑成功' : '添加成功')
        this.menuVisible = false
        this.getList()
      }
    },
    close() {
      this.menu = {
        name: '',
        url: '',
        icon: '',
        type: ''
      }
    },
    linkPost(id) {
      const url = 'admin/api/list'
      const params = {
        menu_id: id
      }
      this.$https.get(url, {params}).then(data => {
        if (data) {
          this.linkVisible = true
          this.linkData = data
          if (!this.post.menuId) this.post.menuId = id
        }
      })
    },
    deletePost(id) {
      this.$confirm('是否删除此接口?', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const url = 'admin/api/delete'
        const params = {
          id
        }
        const data = await this.$https.post(url, params)
        if (data) {
          this.$message({
            type: 'success',
            message: '删除成功!'
          })
          this.linkPost(this.post.menuId)
        }
      }).catch(() => {})
    },
    addPost() {
      this.post.url = ''
      this.post.id = ''
      this.post.type = ''
      this.postVisible = true
    },
    editPost(item) {
      this.post.url = item.requestUrl
      this.post.type = item.method
      this.post.menuId = item.menuId
      this.post.id = item.id
      this.postVisible = true
    },
    postConfirm() {
      const params = {
        menu_id: this.post.menuId,
        request_url: this.post.url,
        method: this.post.type,
        id: this.post.id
      }
      if (this.post.id) params.id = this.post.id
      const url = 'admin/api/edit'
      this.$https.post(url, params).then(data => {
        if (data) {
          this.$message.success(this.post.id ? '编辑成功' : '添加成功')
          this.postVisible = false
          this.linkPost(this.post.menuId)
        }
      })
    },
    linkClose() {
      this.post = {
        url: '',
        type: '',
        menuId: ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  height: 100%;
  display: flex;
  flex-direction: column;
  .menuicon {
    width: 100px;
    cursor: pointer;
    .icon, .menuicon-box {
      width: 100%;
      height: 100px;
    }
    .menuicon-box {
      flex-direction: column;
      border: 1px dashed rgba(0, 0, 0, 0.15);
      box-sizing: border-box;
    }
    .icon {
      position: relative;
      width: 100%;
      overflow: hidden;
      cursor: pointer;
      .exchange {
        position: absolute;
        display: block;
        width: 100%;
        height: 28px;
        line-height: 28px;
        bottom: 0;
        left: 0;
        font-size: 12px;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.45);
        text-align: center;
      }
    }
  }
  .post-item {
    align-items: center;
  }
  .post-item+.post-item {
    margin-top: 24px;
  }
}
</style>
